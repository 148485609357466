import { Checkbox, DatePicker, DefaultButton, Dropdown, IDatePickerStrings, IDropdownOption, Label, MessageBarType, Panel, PanelType, PrimaryButton, TextField } from "@fluentui/react";
import React, { useEffect } from "react";
import { FunctionComponent, useState } from "react";
import {ICalenderDatabaseItem, IActivityChangeLogItem } from "../util/interfaces";
import {DetailsList, DetailsListLayoutMode, IColumn, SelectionMode} from "@fluentui/react";
import Notifications from "./notifications";
import { createDateTimeString } from "../util/functions";
import {getActivityChangeLogItems} from "../services/data"

interface IAdminProps {
	visible:boolean;
	closePanel: () => void;
}

interface IAdminState {
	errorMessage:string;
	currentActivityChangeLogItems:IActivityChangeLogItem[];
    searchFieldValue: string;
}

export default class Aadmin extends React.Component<IAdminProps,IAdminState> {
    constructor(props:IAdminProps) {
        super(props);
        this.state = { 
			errorMessage: '',
			currentActivityChangeLogItems : [],
            searchFieldValue: ""
        };
    }
    

    componentDidMount(){
        this.init();
    }

    init(){
		//console.log("Calling getActivityChangeLogItems");
        getActivityChangeLogItems().then(activityChangeLogItemsResult => {
			//console.log("getActivityChangeLogItems response:", activityChangeLogItemsResult);
			this.setState({currentActivityChangeLogItems : activityChangeLogItemsResult});
		}).catch(error =>{
			console.log("getActivityChangeLogItems error", error);
		});
    }

    render(){
        let filteredLogs = this.state.currentActivityChangeLogItems;

        if(this.state.searchFieldValue !== "") {
            filteredLogs = filteredLogs.filter(log => log.ChangeType.toLowerCase().indexOf(this.state.searchFieldValue.toLowerCase()) !== -1 || log.ChangedBy.toLowerCase().indexOf(this.state.searchFieldValue.toLowerCase()) !== -1 || createDateTimeString(log.LastModifiedDate).indexOf(this.state.searchFieldValue) !== -1);
        }

		const action = () : JSX.Element => { 
			return (
				<div>
					<p>{this.state.errorMessage}</p>
					<DefaultButton onClick={() => this.props.closePanel()} text="Luk" />
				</div>
			)
		};

		const columns: IColumn[] = [
            {
                key: 'ChangeType',
                name: 'Status',
                fieldName: 'ChangeType',
                data: 'string',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
            },
            {
                key: 'ChangedBy',
                name: 'Ændret af',
                fieldName: 'ChangedBy',
                data: 'string',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100
                
            },
            {
                key: 'CreatedDate',
                name: 'Oprettet',
                fieldName: 'CreatedDate',
                data: 'number',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item : IActivityChangeLogItem) => {
                    return <span>{createDateTimeString(item.CreatedDate)}</span>;
                }
                
            },
            {
                key: 'LastModifiedDate',
                name: 'Sidst Ændret',
                fieldName: 'LastModifiedDate',
                data: 'number',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item : IActivityChangeLogItem) => {
                    return <span>{createDateTimeString(item.LastModifiedDate)}</span>;
                }
                
            },
            {
                key: 'Title',
                name: 'Aktivitet',
                fieldName: 'Title',
                minWidth: 8,
                isResizable: true,
                isRowHeader: true,
                data: 'string',
                maxWidth: 100
            },
            {
                key: 'Description',
                name: 'Beskrivelse',
                fieldName: 'Description',
                minWidth: 16,
                isResizable: true,
                isRowHeader: true,
                data: 'string',
                maxWidth: 100
            },
            {
                key: 'Type',
                name: 'Type',
                fieldName: 'Type',
                minWidth: 8,
                isResizable: true,
                isRowHeader: true,
                data: 'string',
                maxWidth: 100
            },
            {
                key: 'Area',
                name: 'SP Miljø',
                fieldName: 'Area',
                data: 'string',
                minWidth: 8,
                isResizable: true,
                isPadded: true,
                maxWidth: 100
            },
            {
                key: 'StartDate',
                name: 'Startdato',
                fieldName: 'StartDate',
                data: 'number',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item : IActivityChangeLogItem) => {
                    return <span>{createDateTimeString(item.StartDate)}</span>;
                }
            },
            {
                key: 'EndDate',
                name: 'Slutdato',
                fieldName: 'EndDate',
                data: 'number',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item : IActivityChangeLogItem) => {
                    return <span>{createDateTimeString(item.EndDate)}</span>;
                }
            },
            {
                key: 'Downtime',
                name: 'Nedetid',
                fieldName: 'Downtime',
                data: 'boolean',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item : IActivityChangeLogItem) => {
                    return <span>{item.Downtime ? 'Ja' : 'Nej'}</span>;
                }
            },
            
        ];

        return(
            <Panel
				headerText={'Ændringslog over aktiviteter'} 
				isOpen={this.props.visible}
				onDismiss={() => this.props.closePanel()}
				closeButtonAriaLabel="Luk"
				isLightDismiss
				onRenderFooterContent={action}
				isFooterAtBottom={true}
				type={PanelType.extraLarge}
			>
                <TextField styles={{
                    fieldGroup: {
                        width: 300
                    }
                }} label="Søg (efter Status, Sidst Ændret eller Ændret af)" value={this.state.searchFieldValue} onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) => this.setState({ searchFieldValue: newValue || "" })} />
                <div>Antal : {filteredLogs.length.toString()}</div>
				<DetailsList
                    items={filteredLogs.sort( (a, b) =>  new Date(b.LastModifiedDate).getTime() - new Date(a.LastModifiedDate).getTime() )}
                    columns={columns}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selectionMode={SelectionMode.none}
                />
				
			</Panel>
       ); 
    }
};