const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';

export const msalConfig = {
    auth: {
        clientId: environment === 'PROD' ? '99bb8cbb-1106-43eb-ada0-894056644b17' : '95b49d0d-2be1-4d9a-ba58-d666a79b3bdc',
        authority: "https://login.microsoftonline.com/common", // Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "/", //Enter_the_Redirect_Uri_Here
        clientSecret: environment === 'PROD' ? 'MO87Q~O8f5P4HrK5X98KVgjt7ipu-eiGRMuzc' : 'poy7Q~xyCyUbelEXjq3TNCAqXQfd8gDmfr2_G',
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    //scopes: ["User.Read"]
    scopes: [`api://${msalConfig.auth.clientId}/user_impersonation`]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};