import { mergeStyleSets, MessageBar, MessageBarType } from "@fluentui/react";
import { FunctionComponent, useEffect, useState } from "react";

interface IProps {
    barType: MessageBarType;
    message: string;
}

const Notifications : FunctionComponent<IProps> = (props : IProps) => {
    const [opacityToOne, setOpacityToOne] = useState<boolean>(false);
    useEffect(() => {
        if(!opacityToOne){
            setOpacityToOne(true);
        }
    }, [opacityToOne])

    return(
        <MessageBar
            messageBarType={props.barType}
            className={`${styles.messageBar} ${opacityToOne ? styles.visible : ''}`}
        >
            {props.message}
        </MessageBar>
    )
}

const styles = mergeStyleSets({
    messageBar: {
        position: 'fixed',
        top: '70px',
        width: '20%',
        right: '10px',
        boxShadow:' 1px 1px 5px #ccc',
        borderRadius: '5px',
        opacity: 0,
        transition: 'all 0.5s'
    },
    visible: {
        opacity: 1
    }
})

export default Notifications;