import { useMsal } from '@azure/msal-react';
import { Icon } from '@fluentui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import styles from './styles/Navbar.module.css';
import { UserRole, IRegHDropdownOption } from '../util/interfaces';
import NewItem from './newitem';
import Admin from './admin';

export interface INavbarProps {
    itemAdded:Function;
    currentUser:string;
    currentUserRole:UserRole;
    dropdownOptions: IRegHDropdownOption[];
}

const Navbar: FunctionComponent<INavbarProps> = (props: INavbarProps) => {
    const [adminPanelVisible, toggleAdminPanel] = useState<boolean>(false);
    const [newItemPanelVisible, toggleNewItemPanel] = useState<boolean>(false);

    useEffect(() => {
        
    }, [])


    let userRoleString:string = '';
    switch(props.currentUserRole.toString()){
        case '0' : 
            userRoleString = '';
            break;
        case '1' : 
            userRoleString = '(Redaktør)';
            break;
        case '2' : 
            userRoleString = '(Administrator)';
            break;

    }


    return (
        <>
            <div className={styles.navbar}>
                <div className={styles.navbarContainer}>
                    <span className={styles.accountName}> Hej {props.currentUser} {userRoleString}</span>
                </div>
                <div className={styles.navbarContainer}>
                    {props.currentUserRole === UserRole.Admin ?
                        <Icon iconName="Admin" className={styles.icon} onClick={() => toggleAdminPanel(true)} />
                        :
                        null
                    }
                    {props.currentUserRole === UserRole.Admin || props.currentUserRole === UserRole.Editor ?
                        <Icon iconName="Add" className={styles.icon} onClick={() => toggleNewItemPanel(true)} />
                        :
                        null
                    }
                </div>
            </div>
            <Admin visible={adminPanelVisible} closePanel={() => toggleAdminPanel(false)}/>
            <NewItem currentUser={props.currentUser} dropdownOptions={props.dropdownOptions} visible={newItemPanelVisible} closePanel={() => toggleNewItemPanel(false)} updatedItem={(newItem) => {
                props.itemAdded(newItem);
            }}/>
        </>
    );
}

export default Navbar;