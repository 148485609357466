import { ChoiceGroup, Dropdown, IDropdownOption, IChoiceGroupOption, ComboBox, SelectableOptionMenuItemType, Checkbox } from "@fluentui/react";
import React from "react";
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import styles from './styles/timeline.module.css';
import { ICalenderDatabaseItem, IRegHDropdownOption, UserRole } from "../util/interfaces";
import ActivitiesTimelineView from "./activitiesTimelineView";
import ActivitiesListView from "./activitiesListView";
//import { isTypeNode } from "typescript";

interface ImainViewProps {
    activities: ICalenderDatabaseItem[];
    dropdownOptions: IRegHDropdownOption[];
    currentUserRole: UserRole;
    currentUser: string;
    onCalendarItemUpdate: (item: ICalenderDatabaseItem) => void;
    onCalendarItemDelete: (RowKey: string) => void;
}

interface ImainViewState {
    viewKey: string;
    titleFilter: string;
    typeFilter: string;
    areaFilter: string;
    versionFilter: string;
    downtimeFilter: string;
    activitiesResult: ICalenderDatabaseItem[];
    activitiesLoaded: Boolean;
}



export default class MainView extends React.Component<ImainViewProps, ImainViewState> {

    private titleFilterOptions: IDropdownOption[] = [];
    private typeFilterOptions: IDropdownOption[] = [];
    private areaFilterOptions: IDropdownOption[] = [];
    private versionFilterOptions: IDropdownOption[] = [];
    private downtimeFilterOptions: IDropdownOption[] = [
        { key: 0, text: 'Alle' },
        { key: 1, text: 'Ja' },
        { key: 2, text: 'Nej' }
    ];

    constructor(props: ImainViewProps) {
        super(props);
        this.state = {
            viewKey: 'Calendar',
            titleFilter: '',
            typeFilter: '',
            areaFilter: '',
            versionFilter: '',
            downtimeFilter: "Alle",
            activitiesResult: [],
            activitiesLoaded: false
        };
    }

    private viewToggleOptions: IChoiceGroupOption[] = [
        {
            key: "Calendar",
            text: "Kalender",
            iconProps: { iconName: 'Calendar' }
        },
        {
            key: "Details",
            text: "Liste",
            iconProps: { iconName: 'BulletedList' }
        }
    ];

    componentDidMount() {
        this.init();
    }

    init() {

        this.titleFilterOptions.push({ key: 0, text: 'Alle' });
        this.typeFilterOptions.push({ key: 0, text: 'Alle' });
        this.areaFilterOptions.push({ key: 'Alle', text: 'Alle', itemType: SelectableOptionMenuItemType.SelectAll });
        this.versionFilterOptions.push({ key: 0, text: 'Alle' });
        if (this.props.dropdownOptions) {
            this.props.dropdownOptions.forEach(option => {
                switch (option.Type) {
                    case "Title":
                        this.titleFilterOptions.push({ key: option.Value, text: option.Value });
                        break;
                    case "Type":
                        this.typeFilterOptions.push({ key: option.Value, text: option.Value });
                        break;
                    case "Area":
                        this.areaFilterOptions.push({ key: option.Value, text: option.Value });
                        break;
                    case "Version":
                        this.versionFilterOptions.push({ key: option.Value, text: option.Value });
                        break;
                }
            });
            this.setState({ areaFilter: this.areaFilterOptions.filter(option => option.key !== "Alle").map(option => option.key).join(";") })
        }
    }

    render() {

        //apply filter
        let filteredActivities = this.props.activities;

        if (this.state.titleFilter && this.state.titleFilter != 'Alle') {
            filteredActivities = filteredActivities.filter(a => a.Title == this.state.titleFilter);
        }
        if (this.state.typeFilter && this.state.typeFilter != 'Alle') {
            filteredActivities = filteredActivities.filter(a => a.Type == this.state.typeFilter);
        }

        if (this.state.versionFilter && this.state.versionFilter != 'Alle') {
            filteredActivities = filteredActivities.filter(a => a.Version == this.state.versionFilter);
        }

        if (this.state.downtimeFilter && this.state.downtimeFilter != 'Alle') {
            filteredActivities = filteredActivities.filter(a => {
                let isTrue: boolean = this.state.downtimeFilter === "Ja";
                return a.Downtime == isTrue;
            });
        }

        if (this.state.areaFilter && this.state.areaFilter != '') {
            filteredActivities = filteredActivities.filter(a => {
                let returnValue: boolean = false;
                this.state.areaFilter.split(";").forEach(areaFilter => {
                    a.Area.split(";")
                        .forEach(areaValue => {
                            if (areaValue === areaFilter) {
                                returnValue = true;
                            }
                        });
                })
                return returnValue;
            });
        } else {
            filteredActivities = [];
        }

        const horizontalGapStackTokens: IStackTokens = {
            childrenGap: 10,
            padding: 10,
        };

        return (
            <div>
                <div>
                    <Stack wrap horizontal horizontalAlign="space-between">
                        <Stack wrap horizontal>

                            <Stack.Item>
                                <ChoiceGroup className={styles.choicegroup} defaultSelectedKey={this.state.viewKey} options={this.viewToggleOptions} onChange={(e, option) => { (option ? this.setState({ viewKey: option.key }) : this.setState({ viewKey: this.state.viewKey })) }} />
                            </Stack.Item>
                            <Stack.Item>
                                <div className={styles.filterWide}>
                                    <Dropdown options={this.titleFilterOptions} title="Title" label="Aktivitet" defaultSelectedKey={0} onChange={(e, option) => { (option ? this.setState({ titleFilter: option.text }) : this.setState({ titleFilter: '' })) }} />
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <div className={styles.filter}>
                                    <Dropdown options={this.typeFilterOptions} title="Type" label="Type" defaultSelectedKey={0} onChange={(e, option) => { (option ? this.setState({ typeFilter: option.text }) : this.setState({ typeFilter: '' })) }} />
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <div className={styles.filter}>
                                    <Dropdown multiSelect options={this.areaFilterOptions} title="Area" label="SP Miljø" selectedKeys={this.getSelectedKeys()} onChange={(e, option) => {
                                        let currentAreasString = "";
                                        if (option?.text === "Alle") {
                                            currentAreasString = option?.selected ? this.areaFilterOptions.filter(option => option.text !== "Alle").map(option => option.text).join(";") : "";
                                            this.setState({ areaFilter: currentAreasString })
                                        } else {
                                            if (this.state.areaFilter === "") {
                                                this.setState({ areaFilter: option?.text || "" })
                                            } else {
                                                let currentAreas: string[] = this.state.areaFilter.split(";");
                                                currentAreas = option?.selected ? [...currentAreas, option.text] : currentAreas.filter(area => area !== option?.text);
                                                currentAreasString = currentAreas.join(";")
                                                this.setState({ areaFilter: currentAreasString })
                                            }
                                        }
                                    }} />
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <div className={styles.filter}>
                                    <Dropdown options={this.versionFilterOptions} title="Version" label="Version" defaultSelectedKey={0} onChange={(e, option) => { (option ? this.setState({ versionFilter: option.text }) : this.setState({ versionFilter: '' })) }} />
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <div className={styles.filter}>
                                    <Dropdown options={this.downtimeFilterOptions} title="Nedetid" label="Nedetid" defaultSelectedKey={0} onChange={(e, option) => { this.setState({ downtimeFilter: option?.text || "Alle" }) }} />
                                </div>
                            </Stack.Item>
                        </Stack>
                        <Stack wrap horizontal>
                            <Stack.Item>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: "20px 20px 0 0" }}>
                                    {/* <div>Leveret af <span style={{ color: "#42a2b7" }}>Region Hovedstaden</span> og <span style={{ color: "#42a2b7" }}>Region Sjælland</span></div> */}
                                    <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                                        <img src="./logoRH.png" width="125" style={{ marginLeft: "1em" }} /><img src="./logoRS.png" width="100" style={{ marginLeft: "2em" }} />
                                    </div>
                                </div>
                            </Stack.Item>
                        </Stack>


                    </Stack>
                </div>

                <div style={{ padding: '10px' }}>

                    {this.state.viewKey == "Details" && filteredActivities.length > 0 &&
                        <ActivitiesListView dropdownOptions={this.props.dropdownOptions} activities={filteredActivities} currentUser={this.props.currentUser} onCalendarItemDelete={this.props.onCalendarItemDelete} onCalendarItemUpdate={this.props.onCalendarItemUpdate} currentUserRole={this.props.currentUserRole} />
                    }
                    {this.state.viewKey == "Calendar" &&
                        <ActivitiesTimelineView currentUser={this.props.currentUser} onCalendarItemDelete={this.props.onCalendarItemDelete} onCalendarItemUpdate={this.props.onCalendarItemUpdate} dropdownOptions={this.props.dropdownOptions} activities={filteredActivities.length > 0 ? filteredActivities : []} currentUserRole={this.props.currentUserRole} />
                    }
                </div>
            </div>
        )
    }

    getSelectedKeys = (): string[] => {
        const returnValue = this.state.areaFilter === ""
            ? [] : this.state.areaFilter.split(";").length === this.areaFilterOptions.length - 1
                ? this.state.areaFilter.concat(";Alle").split(";")
                : this.state.areaFilter.split(";");

        return returnValue;
    }
}

