import { Callout, FontWeights, Label, mergeStyleSets, Text, Separator, DefaultButton, PrimaryButton, MessageBarType } from "@fluentui/react";
import { FunctionComponent, useState } from "react";
import { deleteActivity, addActivityChangeLogItem } from "../services/data";
import { createDateTimeString } from "../util/functions";
import { ICalenderDatabaseItem, IActivityChangeLogItem, UserRole } from "../util/interfaces";
import Notifications from "./notifications";

interface IEventCalloutProps {
    visible: boolean;
    item?: ICalenderDatabaseItem;
    target?: MouseEvent;
    currentUserRole: UserRole;
    currentUser: string;
    hideCallout: () => void;
    showEditPanel: () => void;
    deletedItem: (deletedItem: ICalenderDatabaseItem) => void;
}

const EventCallout: FunctionComponent<IEventCalloutProps> = (props) => {
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationMessage, setNotificationMessage] = useState<string>("");


    const deleteCurrentItem = async (item : any) => {
        if(item){
            //console.log('calling deleteActivity')
            const response = await deleteActivity(item);
            //console.log('deleteActivity response', response)

            const activityChangeLogItem: IActivityChangeLogItem = {
                Title: item.Title,
                Type: item.Type,
                Description: item.Description,
                Area: item.Area,
                StartDate: item.StartDate,
                EndDate: item.EndDate,
                Downtime: item.Downtime,
                Version: item.Version,
                CreatedDate: item.CreatedDate,
                LastModifiedDate: item.LastModifiedDate,
                Owner: item.Owner,
                ChangedBy: props.currentUser,
                ChangeType: 'Slettet'
		    }

            const activityLogChangeResponse = await addActivityChangeLogItem(activityChangeLogItem);
            //console.log(activityLogChangeResponse);

            props.hideCallout();
            props.deletedItem(item);
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 5000)
   
        }
   }

    return (
        <>
            {props.visible && props.item ?
                <Callout
                    gapSpace={0}
                    target={props.target}
                    onDismiss={props.hideCallout}
                    onMouseLeave={props.hideCallout}
                    className={styles.callout}
                >
                    <Text block variant="xLarge" className={styles.title}>
                        {props.item.Title}
                    </Text>
                    <Text block variant="small">{createDateTimeString(props.item.StartDate)} - {createDateTimeString(props.item.EndDate)}</Text>
                    <Separator />
                    <Text block variant="small">
                        {props.item.Description}
                    </Text>
                    <Label>SP Miljø</Label>
                    <Text block>
                        {props.item.Area.split(";").join(", ")}
                    </Text>
                    <Label>Version</Label>
                    <Text block>
                        {props.item.Version}
                    </Text>
                    <Label>Nedetid</Label>
                    <Text block>
                        {props.item.Downtime ? 'Ja' : 'Nej'}
                    </Text>
                    <Label>Oprettelsesdato</Label>
                    <Text block>
                        {createDateTimeString(props.item.CreatedDate)}
                    </Text>
                    <Label>Sidst redigeret</Label>
                    <Text block>
                        {createDateTimeString(props.item.LastModifiedDate)}
                    </Text>
                    {
                        props.currentUserRole === UserRole.Admin || ( props.currentUserRole === UserRole.Editor && props.item.Owner === props.currentUser ) ?
                            <div className={styles.bottomContainer}>
                                <PrimaryButton text="Rediger" onClick={ props.showEditPanel } />
                                {
                                    props.currentUserRole === UserRole.Admin
                                    && <DefaultButton text="Slet" className={styles.bottomSpacing} onClick={() => { deleteCurrentItem(props.item); setShowNotification(true); setNotificationMessage("Slettet"); setTimeout(() => {setShowNotification(false)}, 5000 )}} />
                                }
                            </div>
                        :
                        null
                    }
                </Callout>
                :
                null
            }
            { showNotification ? <Notifications barType={MessageBarType.success} message={notificationMessage}/> : null }
            
        </>
    )
}



const styles = mergeStyleSets({
    callout: {
        maxWidth: '25%',
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    bottomContainer: {
        display: 'flex',
        margin: '10px 0px'

    },
    bottomSpacing : {
        marginLeft: '8px'
    }
});

export default EventCallout;