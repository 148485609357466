import React from "react";
// import styles from '../styles/frontpage.module.css';
import { ColumnActionsMode, PrimaryButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";
import { dir } from "console";

//login using popups
function handleLogin(instance: IPublicClientApplication) {
    instance.loginPopup(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();


    return (
        // <Button variant="secondary" className="ml-auto" onClick={() => handleLogin(instance)}>Sign in using Popup</Button>
        <div style={{ margin: "50px auto", textAlign: "center", height: '100vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{paddingBottom:'10px', fontWeight:'bold'}}>Du skal logge ind for at se Sundhedsplatformens release kalender</div>
                <div>Leveret af </div>
                <div style={{ display: 'flex', alignItems: 'center', margin:'20px 0' }}>
                    <img src="./logoRH.png" width="200" style={{ marginLeft: "1em" }} /><img src="./logoRS.png" width="150" style={{ marginLeft: "2em" }} />
                </div>
            </div>
            <PrimaryButton style={{backgroundColor:"#c3c3c3", borderColor:"#000", color:"#000"}} text="Login" onClick={() => handleLogin(instance)} />
            
        </div>
    );
}

//login using redirects

// function handleLogin(instance) {
//     instance.loginRedirect(loginRequest).catch(e => {
//         console.error(e);
//     });
// }

// /**
//  * Renders a button which, when selected, will redirect the page to the login prompt
//  */
// export const SignInButton = () => {
//     const { instance } = useMsal();

//     return (
//         <Button variant="secondary" className="ml-auto" onClick={() => handleLogin(instance)}>Sign in using Redirect</Button>
//     );
// }
