import { DatePicker, DefaultButton, Dropdown, IDatePickerStrings, IDropdownOption, MessageBarType, Panel, PrimaryButton, TextField, ChoiceGroup, IChoiceGroupOption, SelectableOptionMenuItemType  } from "@fluentui/react";
import React, { useEffect } from "react";
import { FunctionComponent, useState } from "react";
import { addActivity, updateActivity, addActivityChangeLogItem } from "../services/data";
import { ICalenderDatabaseItem, IActivityChangeLogItem, IRegHDropdownOption } from "../util/interfaces";
import Notifications from "./notifications";

declare const moment: any; 

interface IProps { 
	visible: boolean;
	closePanel: (refresh: boolean) => void;
	updatedItem: (updatedItem: ICalenderDatabaseItem) => void;
	item?: ICalenderDatabaseItem;
	dropdownOptions : IRegHDropdownOption[];
	currentUser:string;
}

const danishStrings: IDatePickerStrings = {
	days: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
	shortDays: [ 'Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
	goToToday: 'I dag',
	months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
	shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
}

const onFormatDate = (date?: Date): string => {
	return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
};

const addTimeToDate = (date: Date, time: string) => {
	const timeSplit = time.split(':');
	try {
		const hours: number = +timeSplit[0];
		const minutes: number = +timeSplit[1];
		date.setHours(hours);
		date.setMinutes(minutes);
	} catch (error) {

	}
}

const buttonStyles = { root: { marginRight: 8 } };

let areaOptions: IDropdownOption[] = [{ key: 'Alle', text: 'Alle', itemType: SelectableOptionMenuItemType.SelectAll }];
let titleOptions: IDropdownOption[] = [];
let typeOptions: IDropdownOption[] = [];
let versionOptions: IDropdownOption[] = [];

const NewItem: FunctionComponent<IProps> = (props) => {
	const [title, setTitle] = useState<string>("");
	const [type, setType] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [area, setArea] = useState<string>("");
	const [startDate, setStartDate] = useState<Date>();
	const [startTime, setStartTime] = useState<string>("08:00");
	const [endDate, setEndDate] = useState<Date>();
	const [endTime, setEndTime] = useState<string>("23:30");
	const [downtime, setDowntime] = useState<boolean>(false);
	const [version, setVersion] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");

	useEffect(() => {

		if(props.dropdownOptions){
			titleOptions = [];
			typeOptions = [];
			areaOptions = [];
			versionOptions = [];
			props.dropdownOptions.forEach(option => {
				switch (option.Type) {
					case "Title":
					titleOptions.push({ key: option.Value, text: option.Value });
					break;
					case "Type":
					typeOptions.push({ key: option.Value, text: option.Value });
					break;
					case "Area":
					areaOptions.push({ key: option.Value, text: option.Value });
					break;
					case "Version":
					versionOptions.push({ key: option.Value, text: option.Value });
					break;
				}
			});
		}

		if(props.item){
			//console.log("Edit mode started");
			//console.log(props.item);
			setTitle(props.item.Title);
			setType(props.item.Type);
			setDescription(props.item.Description);
			setArea(props.item.Area);
			setStartDate(props.item.StartDate);
			setStartTime(moment(props.item.StartDate).format('HH:mm'));
			setEndDate(props.item.EndDate);
			setEndTime(moment(props.item.EndDate).format('HH:mm'));
			setDowntime(props.item.Downtime);
			setVersion(props.item.Version);
		}
	}, [props.item]);

	const footerNew = () : JSX.Element => { return (
		<div>
			<p>{errorMessage}</p>
			<PrimaryButton onClick={() => createUpdateItem()} text={"Gem"} styles={buttonStyles} disabled={loading} />
			<DefaultButton onClick={() => props.closePanel(false)} text="Annuler" />
		</div>
	)};

	const getSelectedKeys = (): string[] => {
        const returnValue = area === "" 
        ? [] : area.split(";").length === areaOptions.length - 1
        ? area.concat(";Alle").split(";")  
        : area.split(";");

        return returnValue;
    }

	const createUpdateItem = async () => {

		if (!title || !area || !startDate || !startTime || !endDate || !endTime) {
			setErrorMessage("Du mangler at udfylde de påkrævede felter");
			return;
		}

		addTimeToDate(startDate, startTime);
		addTimeToDate(endDate, endTime);
		setLoading(true);
		const calendarItem: ICalenderDatabaseItem = {
			Title: title,
			Type: type,
			Description: description,
			Area: area,
			StartDate: startDate,
			EndDate: endDate,
			Downtime: downtime,
			Version: version,
			CreatedDate: new Date(),
			LastModifiedDate: new Date(),
			Owner: props.currentUser
		}
		
		const activityChangeLogItem: IActivityChangeLogItem = {
			Title: title,
			Type: type,
			Description: description,
			Area: area,
			StartDate: startDate,
			EndDate: endDate,
			Downtime: downtime,
			Version: version,
			CreatedDate: new Date(),
			LastModifiedDate: new Date(),
			Owner: props.currentUser,
			ChangedBy: props.currentUser,
			ChangeType: ''
		}

		try {

			if(props.item){
				//console.log(props.item);
				//console.log("Update Calendar item", calendarItem);
				calendarItem.PartitionKey = props.item.PartitionKey;
				calendarItem.RowKey = props.item.RowKey;
				calendarItem.CreatedDate = props.item.CreatedDate

				await updateActivity(calendarItem);

				activityChangeLogItem.ChangeType = 'Ændret';
				activityChangeLogItem.CreatedDate = props.item.CreatedDate;
				const activityLogChangeResponse = await addActivityChangeLogItem(activityChangeLogItem);
				//console.log(activityLogChangeResponse);

			}else{
				//console.log("New Calendar item", calendarItem);
				const response = await addActivity(calendarItem);
				calendarItem.RowKey = response.rowkey;
				//console.log(response);

				activityChangeLogItem.ChangeType = 'Oprettet';
				const activityLogChangeResponse = await addActivityChangeLogItem(activityChangeLogItem);
				//console.log(activityLogChangeResponse);
			}

			props.closePanel(true);
			props.updatedItem(calendarItem);
			setLoading(false);
			setTitle("");
			setType("");
			setDescription("");
			setStartDate(new Date())
			setEndDate(new Date())
			setArea("");
			setDowntime(false);
			setVersion("");

		} catch (error) {
			setLoading(false);
			setErrorMessage("error");
			console.log(error);
		}
	}
	
	const downTimeOptions: IChoiceGroupOption[] = [
		{ key: '1', text: 'Ja' },
		{ key: '2', text: 'Nej' },
	  ];

	return (
		<>
			<Panel
				headerText={props.item ? 'Rediger aktivitet': 'Tilføj ny aktivitet'} 
				isOpen={props.visible}
				onDismiss={() => props.closePanel(false)}
				closeButtonAriaLabel="Close"
				isLightDismiss
				onRenderFooterContent={footerNew}
				isFooterAtBottom={true}
			>
				<Dropdown options={titleOptions} title="Title" label="Aktivitet" defaultSelectedKey={title} required onChange={(e, option) => option ? setTitle(option.text) : setTitle("")} />
				<TextField title="Description" label="Beskrivelse" value={description} placeholder="Vi opgraderer serveren i weekenden" multiline onChange={(e, value) => value ? setDescription(value) : setDescription("")} />
				<Dropdown options={versionOptions} title="Version" label="Version" defaultSelectedKey={version} onChange={(e, option) => option ? setVersion(option.text) : setVersion("")} />
				<Dropdown options={typeOptions} title="Type" label="Type" defaultSelectedKey={type} required onChange={(e, option) => option ? setType(option.text) : setType("")} />
				<Dropdown multiSelect options={areaOptions} title="Area" label="SP Miljø" selectedKeys={getSelectedKeys()} required onChange={(e, option) => {
					let currentAreasString = "";
					if(option?.text === "Alle") {
							currentAreasString = option.selected ? areaOptions.filter(option => option.text !== "Alle").map(option => option.text).join(";") : "";
						setArea(currentAreasString);
					} else {
						if(area === "") {
							setArea(option?.text || "")
						} else {
							let currentAreas: string[] = area.split(";");
							currentAreas = option?.selected ? [...currentAreas, option.text] : currentAreas.filter(area => area !== option?.text);
							currentAreasString = currentAreas.join(";")
							setArea(currentAreasString);
						}
					}
				}} />
				<DatePicker
					firstDayOfWeek={0} // Monday
					placeholder="Vælg start dato"
					label="Start dato"
					value={startDate}
					showWeekNumbers={true}
					strings={danishStrings}
					minDate={new Date('2021-12-01')}
					isRequired={true}
					formatDate={onFormatDate}
					onSelectDate={(date) => date ? setStartDate(date) : setStartDate(new Date())}
				/>
				<TextField title="Start Time" label="Start Tidspunkt" value={startTime} type="time" onChange={(e, value) => value ? setStartTime(value) : setStartTime("00:00")} />
				<DatePicker
					firstDayOfWeek={0} // Monday
					placeholder="Vælg slutdato dato"
					label="Slut dato"
					value={endDate}
					showWeekNumbers={true}
					strings={danishStrings}
					minDate={startDate ? startDate : new Date()}
					isRequired={true}
					formatDate={onFormatDate}
					onSelectDate={(date) => date ? setEndDate(date) : setEndDate(new Date())}
				/>

				<TextField title="End Time" label="Slut Tidspunkt" value={endTime} type="time" required onChange={(e, value) => value ? setEndTime(value) : setEndTime("23:59")} />
				
				<ChoiceGroup defaultSelectedKey={downtime ? "1" : "2"} options={downTimeOptions} label="Nedetid" required={true} onChange={(ev, option) => { setDowntime(option?.key == '1' ? true : false!)} } />
			</Panel>
		</>
	);
}

export default NewItem;