import React from "react";
import { IActivityChangeLogItem, ICalenderDatabaseItem, IRegHDropdownOption, UserRole } from "../util/interfaces";
import { DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, PrimaryButton, SelectionMode } from "@fluentui/react";
import { createDateTimeString } from "../util/functions";
import { addActivityChangeLogItem, deleteActivity } from "../services/data";
import NewItem from "./newitem";

interface IActivitiesListViewProps {
    activities: ICalenderDatabaseItem[];
    currentUserRole: UserRole;
    currentUser: string;
    dropdownOptions: IRegHDropdownOption[];
    onCalendarItemUpdate: (item: ICalenderDatabaseItem) => void;
    onCalendarItemDelete: (RowKey: string) => void;
}

interface IActivitiesListViewState {
    showEditPanel: boolean;
    currentTimelineItem: ICalenderDatabaseItem | undefined;
}

export default class ActivitiesListView extends React.Component<IActivitiesListViewProps, IActivitiesListViewState> {
    constructor(props: IActivitiesListViewProps) {
        super(props);
        this.state = {
            showEditPanel: false,
            currentTimelineItem: undefined
        };
    }

    public deleteCurrentItem = async (item : ICalenderDatabaseItem) => {
        if(item){
            //console.log('calling deleteActivity')
            const response = await deleteActivity(item);
            //console.log('deleteActivity response', response)

            const activityChangeLogItem: IActivityChangeLogItem = {
                Title: item.Title,
                Type: item.Type,
                Description: item.Description,
                Area: item.Area,
                StartDate: item.StartDate,
                EndDate: item.EndDate,
                Downtime: item.Downtime,
                Version: item.Version,
                CreatedDate: item.CreatedDate,
                LastModifiedDate: item.LastModifiedDate,
                Owner: item.Owner,
                ChangedBy: this.props.currentUser,
                ChangeType: 'Slettet'
		    }

            const activityLogChangeResponse = await addActivityChangeLogItem(activityChangeLogItem);
            //console.log(activityLogChangeResponse);

            this.props.onCalendarItemDelete(item.RowKey || "");

        }
   }


    componentDidMount() {
        this.init();
    }

    init() {


        //alert('init called');


    }

    render() {

        const columns: IColumn[] = [
            {
                key: 'Title',
                name: 'Aktivitet',
                fieldName: 'Title',
                minWidth: 8,
                isResizable: true,
                isRowHeader: true,
                data: 'string',
                maxWidth: 100
            },
            {
                key: 'Description',
                name: 'Beskrivelse',
                fieldName: 'Description',
                minWidth: 8,
                isResizable: true,
                isRowHeader: true,
                data: 'string',
                maxWidth: 100,
            },
            {
                key: 'Version',
                name: 'Version',
                fieldName: 'Version',
                data: 'string',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100
            },
            {
                key: 'Type',
                name: 'Type',
                fieldName: 'Type',
                minWidth: 8,
                isResizable: true,
                isRowHeader: true,
                data: 'string',
                maxWidth: 100
            },
            {
                key: 'Area',
                name: 'SP Miljø',
                fieldName: 'Area',
                data: 'string',
                minWidth: 8,
                isResizable: true,
                isPadded: true,
                maxWidth: 100
            },
            {
                key: 'StartDate',
                name: 'Startdato',
                fieldName: 'StartDate',
                data: 'number',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item: ICalenderDatabaseItem) => {
                    return <span>{createDateTimeString(item.StartDate)}</span>;
                }
            },
            {
                key: 'EndDate',
                name: 'Slutdato',
                fieldName: 'EndDate',
                data: 'number',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item: ICalenderDatabaseItem) => {
                    return <span>{createDateTimeString(item.EndDate)}</span>;
                }
            },
            {
                key: 'Downtime',
                name: 'Nedetid',
                fieldName: 'Downtime',
                data: 'boolean',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item: ICalenderDatabaseItem) => {
                    return <span>{item.Downtime ? 'Ja' : 'Nej'}</span>;
                }
            },
            {
                key: 'CreatedDate',
                name: 'Oprettet dato',
                fieldName: 'CreatedDate',
                data: 'number',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item: ICalenderDatabaseItem) => {
                    return <span>{createDateTimeString(item.CreatedDate)}</span>;
                }
            },
            {
                key: 'LastModifiedDate',
                name: 'Sidst redigeret dato',
                fieldName: 'LastModifiedDate',
                data: 'number',
                minWidth: 8,
                isResizable: true,
                maxWidth: 100,
                onRender: (item: ICalenderDatabaseItem) => {
                    return <span>{createDateTimeString(item.LastModifiedDate)}</span>;
                }
            },
            {
                key: "actions",
                name: "Handlinger",
                fieldName: "Handlinger",
                data: "text",
                minWidth: 8,
                isResizable: false,
                onRender: (item) => {
                    return this.props.currentUserRole === UserRole.Admin || ( this.props.currentUserRole === UserRole.Editor && item.Owner === this.props.currentUser ) ?
                                <div>
                                    <PrimaryButton text="Rediger" style={{ marginRight: 15 }} onClick={() => {
                                        this.setState({ 
                                            showEditPanel: true,
                                            currentTimelineItem: item
                                         });
                                    }} />
                                    {
                                        this.props.currentUserRole === UserRole.Admin
                                        && <DefaultButton text="Slet" onClick={() => { this.deleteCurrentItem(item)}} />
                                    }
                                </div>
                            :
                            null
                }
            }
        ];

        const sortedActivites = this.props.activities.sort((a,b) => new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()).filter(activity => new Date(activity.EndDate) >= new Date())

        return (
            <div>
                <DetailsList
                    items={sortedActivites}
                    columns={columns}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selectionMode={SelectionMode.none}
                />
                {this.state.showEditPanel &&
                    <NewItem currentUser={this.props.currentUser} dropdownOptions={this.props.dropdownOptions} visible={this.state.showEditPanel} closePanel={() => { this.setState({ showEditPanel: false }); }} item={this.state.currentTimelineItem} updatedItem={(updatedItem: ICalenderDatabaseItem) => {
                        this.props.onCalendarItemUpdate(updatedItem);
                    }} />
                }
            </div>
        );
    }

};

