import React from "react";
import { ICalenderDatabaseItem, TimelineItem, IRegHDropdownOption, UserRole } from "../util/interfaces";
import Timeline from "react-vis-timeline-2";
import { TimelineGroup } from "vis-timeline/types";
import EventCallout from "./eventCallout";
import NewItem from "./newitem";
import styles from "./styles/timeline.module.css";

import "./styles/timeline.module.css";
import moment from 'moment';
import {createDateTimeString} from '../util/functions'

interface IActivitiesTimelineViewProps {
    activities: ICalenderDatabaseItem[];
    dropdownOptions: IRegHDropdownOption[];
    currentUserRole: UserRole;
    currentUser: string;
    onCalendarItemUpdate: (item: ICalenderDatabaseItem) => void;
    onCalendarItemDelete: (RowKey: string) => void;
}

interface IActivitiesTimelineViewState {
    showEventCallout: boolean;
    showEditPanel: boolean;
    timelineClickedItemTarget: any;
    currentTimelineItem: any;

}

export default class CustomTimeline extends React.Component<IActivitiesTimelineViewProps, IActivitiesTimelineViewState> {
    constructor(props: IActivitiesTimelineViewProps) {
        super(props);
        this.state = {
            showEventCallout: false,
            showEditPanel: false,
            timelineClickedItemTarget: null,
            currentTimelineItem: null,
        };
    }

    private timelineRef = React.createRef<Timeline>();

    private onTimelineItemRender = (item: ICalenderDatabaseItem): string => {
        const template: string = `
            <div>
                <span>${createDateTimeString(item.StartDate)} - ${createDateTimeString(item.EndDate)}</span>
                <p>${item.Type}</p>
                <p><strong>${item.Title}</strong></p>
                <span>${item.Area.split(';').join(', ')}</span>
            </div>
        `
        return template;
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.activities !== this.props.activities) {
            this.timelineRef.current?.items.clear();
            this.init();
        }
    }

    init() {
        let timelineItems: TimelineItem[] = [];

        this.props.activities.map(activity => {
            activity.StartDate = new Date(activity.StartDate);
            activity.EndDate = new Date(activity.EndDate);
            const timeLineItem: TimelineItem = {
                content: this.onTimelineItemRender(activity),
                id: activity.RowKey!,
                start: activity.StartDate,
                end: activity.EndDate === activity.StartDate ? undefined : activity.EndDate,
                group: 1,
                className: `${styles.timelineitem} ${activity.Area.split(";").indexOf("PROD") !== -1 ? styles.prodBg : ''} ${activity.Area === "N/A" ? styles.areaBg : ''} ${styles.itemCalendar}`,
                type: this.isUnderTwentyFourHours(activity.StartDate, activity.EndDate)
            }
            timelineItems.push(timeLineItem);
        });
        //console.log('timelineitems pushed', timelineItems);
        this.timelineRef.current?.items.add(timelineItems);
    }

    render() {
        const initialItems: TimelineItem[] = [];
        const initialGroups: TimelineGroup[] = [{ id: 1, content: "" }];
        const options = {
            locale: 'da',
            width: '100%',
            height: '100px',
            minHeight: '80vh',
            orientation: 'top',
            selectable: false,
            onInitialDrawComplete: () => {
                //console.log(this.timelineRef.current?.items, "items");
                this.timelineRef.current?.timeline.redraw();
                this.timelineRef.current?.timeline.moveTo(new Date());
            },
            zoomMax: 2629746000 * 6 // max Zoom 6 months
        };

        const timelineMouseOver = (activity: any) => {
            if (activity.item) {
                let currentActivity = this.props.activities.find(a => a.RowKey === activity.item);
                this.setState({ timelineClickedItemTarget: activity.event, currentTimelineItem: currentActivity, showEventCallout: true });
            }
        }
        
        return (
            <div>
                <Timeline
                    ref={this.timelineRef}
                    initialItems={initialItems}
                    initialGroups={initialGroups}
                    options={options}
                    mouseOverHandler={timelineMouseOver}
                />
                {this.state.showEventCallout &&
                    <EventCallout
                        currentUser={this.props.currentUser}
                        hideCallout={() => this.setState({ showEventCallout: false })}
                        showEditPanel={() => { this.setState({ showEditPanel: true }) }}
                        item={this.state.currentTimelineItem}
                        target={this.state.timelineClickedItemTarget}
                        visible={this.state.showEventCallout}
                        deletedItem={(deletedItem: ICalenderDatabaseItem) => {
                            this.props.onCalendarItemDelete(deletedItem.RowKey || "");

                        }}
                        currentUserRole={this.props.currentUserRole}
                    />
                }
                {this.state.showEditPanel &&
                    <NewItem currentUser={this.props.currentUser} dropdownOptions={this.props.dropdownOptions} visible={this.state.showEditPanel} closePanel={() => { this.setState({ showEditPanel: false }); }} item={this.state.currentTimelineItem} updatedItem={(updatedItem: ICalenderDatabaseItem) => {
                        this.props.onCalendarItemUpdate(updatedItem);
                    }} />
                }
            </div>

        );
    }

    isUnderTwentyFourHours = (startDate: Date, endDate: Date): "box" | "point" | "range" | "background" | undefined => {
        var date1 = new Date(startDate).getTime() + (1 * 24 * 60 * 60 * 1000);
        if (date1 < (endDate as any)) {
            return "range"
        } else {
            return "box"
        }
    }

};

