import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import './index.css';
import App from './App';
// import { msalInstance } from './services/msal';
// import { EventType } from '@azure/msal-browser';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

initializeIcons();

const msalInstance = new PublicClientApplication(msalConfig);


ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// const accounts = msalInstance.getAllAccounts();
// if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
// }

// msalInstance.addEventCallback((event) => {
//   if(event.eventType === EventType.LOGIN_SUCCESS && event.payload){
//     const { accounts } = useMsal();
//     msalInstance.setActiveAccount(accounts[0]);
//   }
// });

// ReactDOM.render(
//   <React.StrictMode>
//     <MsalProvider instance={msalInstance}>
//       <App />
//     </MsalProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );