import { ICalenderDatabaseItem, IActivityChangeLogItem } from "../util/interfaces";

export async function getAllowedTenants(accessToken: string) {

    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';

    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'



    const headers = new Headers();

    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {

        method: "GET",

        headers: headers,

    };

    return fetch(`${apiPrefix}/api/GetAllowedTenants`, options)

        .then(response => response.json())

        .catch(error => { console.log(error); throw error });

}

export async function getAdminUsers(accessToken: string, currentUser: string) {
    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';
    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'
    const payload = JSON.stringify({ email: currentUser });

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "POST",
        headers: headers,
        body: payload
    };
    return fetch(`${apiPrefix}/api/GetAdminUser`, options)
        .then(response => response.json())
        .catch(error => { console.log(error); throw error });
}



export async function getDropDownOptions(accessToken: string) {
    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';
    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers,

    };
    return fetch(`${apiPrefix}/api/GetDropdownOptions`, options)
        .then(response => response.json())
        .catch(error => { console.log(error); throw error });
}

export async function getActivities(accessToken: string) {
    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';
    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers,

    };
    return fetch(`${apiPrefix}/api/GetCalendarItems`, options)
        .then(response => response.json())
        .catch(error => { console.log(error); throw error });
}

export async function addActivity(item: ICalenderDatabaseItem) {
    const accessToken = localStorage.getItem("msal_access_token");
    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';
    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(item)
    };
    return fetch(`${apiPrefix}/api/AddCalendarItem`, options)
        .then(response => response.json())
        .catch(error => { console.log(error); throw error });
}

export async function updateActivity(item: ICalenderDatabaseItem) {
    const accessToken = localStorage.getItem("msal_access_token");
    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';
    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(item)
    };
    return fetch(`${apiPrefix}/api/UpdateCalendarItem`, options)
        .then(response => response.text())
        .catch(error => { console.log(error); throw error });
}

export async function deleteActivity(item: ICalenderDatabaseItem) {
    const accessToken = localStorage.getItem("msal_access_token");
    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';
    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(item)
    };
    return fetch(`${apiPrefix}/api/DeleteCalendarItem`, options)
        .then(response => response.text())
        .catch(error => { console.log(error); throw error });
}

export async function getActivityChangeLogItems() {
    const accessToken = localStorage.getItem("msal_access_token");
    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';
    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "GET",
        headers: headers,

    };
    return fetch(`${apiPrefix}/api/GetCalendarRequestItems`, options)
        .then(response => response.json())
        .catch(error => { console.log(error); throw error });
}

export async function addActivityChangeLogItem(item: IActivityChangeLogItem) {
    const accessToken = localStorage.getItem("msal_access_token");
    const environment = window.location.host === 'release-kalender.sundhedsplatform.dk' ? 'PROD' : 'DEV';
    const apiPrefix = environment === 'PROD' ? 'https://release-schedule-api.azurewebsites.net' : 'https://release-schedule-api-test.azurewebsites.net'

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);
    const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(item)
    };
    return fetch(`${apiPrefix}/api/AddCalendarRequestItem`, options)
        .then(response => response.json())
        .catch(error => { console.log(error); throw error });
}

