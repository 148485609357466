export interface IGlobalVars {
    functionAccessToken: string;
    graphAccessToken: string;
    userType: UserRole;
    userEmail: string;
    tokenExpires?: Date;
}

export interface IUser {
    PartitionKey: string;
    RowKey: string;
    Timestamp: string;
    email: string;
    role: string;
}

export enum UserRole {
    User,
    Editor,
    Admin
}

export interface IRegHDropdownOption {
    Type: string;
    Value: string;
    SortIndex: number;
}

export enum RequestMethod {
    GET = "GET",
    POST = "POST",
    DELETE = "DELETE"
}

export interface ICalenderDatabaseItem {
    PartitionKey?: string;
    RowKey?: string;
    key?:string
    Title: string;
    Area: string;
    Type: string;
    Version: string;
    Downtime: boolean;
    Description: string;
    StartDate: Date;
    EndDate: Date;
    CreatedDate: Date;
    LastModifiedDate: Date;    
    Owner: string;
}

export interface IActivityChangeLogItem {
    PartitionKey?: string;
    RowKey?: string;
    key?:string
    Title: string;
    Area: string;
    Type: string;
    Version: string;
    Downtime: boolean;
    Description: string;
    StartDate: Date;
    EndDate: Date;
    CreatedDate: Date;
    LastModifiedDate: Date;    
    Owner: string;
    ChangedBy:string;
    ChangeType:string;
}

// Timeline interfaces
export interface TimelineItem {
    className?: string;
    align?: 'auto' | 'center' | 'left' | 'right';
    content: string;
    end?: Date | number | string;
    group?: string | number;
    id: string | number;
    start: Date | number | string;
    style?: string;
    subgroup?: string | number;
    title?: string;
    type?: 'box' | 'point' | 'range' | 'background';
    editable?: boolean; // false always
    selectable?: boolean;
}