import React, { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import {MessageBarType, Spinner, SpinnerSize} from "@fluentui/react";
import './App.css';
import { UserRole, IRegHDropdownOption, ICalenderDatabaseItem } from './util/interfaces';
import Navbar from './components/navbar';
import Mainview from './components/mainView';
import { SignInButton } from './components/signInButton';
import { loginRequest } from "./authConfig";

import { getAdminUsers, getDropDownOptions, getActivities, getAllowedTenants } from './services/data'
import { Fade, Grow, Zoom } from '@mui/material';
import { PropagateLoader } from 'react-spinners';
import Notifications from "./components/notifications";


function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <Main />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignInButton />
      </UnauthenticatedTemplate>
    </>
  );
}

function Main() {
  const [getDataCalled, setGetDataCalled] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const [isAllowed, setIsAllowed] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [currentUserRole, setCurrentUserRole] = useState<UserRole>(UserRole.User);
  const [dropdownOptions, setDropdownOptions] = useState<IRegHDropdownOption[]>([]);
  const [calendarItems, setCalendarItems] = useState<ICalenderDatabaseItem[]>([]);

  const [currentUserRoleLoaded, setCurrentUserRoleLoaded] = useState(false);
  const [dropdownOptionsLoaded, setDropdownOptionsLoaded] = useState(false);
  const [calendarItemsLoaded, setCalendarItemsLoaded] = useState(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");


  const { instance, accounts } = useMsal();
  const username = accounts[0] && accounts[0].username;

  const onCalendarItemCreate = (newItem: any) => {
    //console.log("new item", newItem)
    setNotificationMessage("Oprettet!")
    setShowNotification(true);
    setCalendarItems([...calendarItems, newItem]);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000)
  }

  const onCalendarItemDelete = (RowKey: string) => {
    const newCalendarItems = [...calendarItems].filter(item => item.RowKey !== RowKey);
    setNotificationMessage("Slettet!")
    setShowNotification(true);
    setCalendarItems(newCalendarItems);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000)
  }

  const onCalendarItemUpdate = (item: ICalenderDatabaseItem) => {
    const newCalendarItems = calendarItems.map(calendarItem => {
      if (calendarItem.RowKey === item.RowKey) {
        return item;
      } else {
        return calendarItem;
      }
    });
    setNotificationMessage("Opdateret!")
    setShowNotification(true);
    setCalendarItems([...newCalendarItems]);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000)
  }


  function GetData() {
    setGetDataCalled(true);

    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((tokenResponse) => {

      //console.log("Access token gained silently", tokenResponse);
      localStorage.setItem("msal_access_token", tokenResponse.accessToken);
      //console.log("User", tokenResponse.account?.username);
      let currentUser = tokenResponse.account?.username;
      setCurrentUser(currentUser ? currentUser : '');

      getAllowedTenants(tokenResponse.accessToken)
        .then(getAllowedTenantsResponse => {
          //console.log("getAllowedTenants response: ", getAllowedTenantsResponse);

          let foundTenant: boolean = false;

          getAllowedTenantsResponse.forEach((allowedTenant: any) => {
            if (allowedTenant.guid === tokenResponse.tenantId) {
              foundTenant = true;
            }
          });

          setIsAllowed(foundTenant);
          setHasLoaded(true);

        })
        .catch((error: any) => { console.log('getAllowedTenants error', error); })

      //console.log("Calling getAdminUsers");
      getAdminUsers(tokenResponse.accessToken, username).then(getAdminUsersResponse => {
        //console.log("getAdminUser response: ", getAdminUsersResponse);
        if (getAdminUsersResponse.userNotFound) {
          setCurrentUserRole(UserRole.User);
          setCurrentUserRoleLoaded(true);
        } else {
          if (getAdminUsersResponse.role === 'editor') {
            setCurrentUserRole(UserRole.Editor);
            setCurrentUserRoleLoaded(true);
          } else if (getAdminUsersResponse.role === 'admin') {
            setCurrentUserRole(UserRole.Admin);
            setCurrentUserRoleLoaded(true);
          }
        }
      }).catch(error => {
        console.log("getAdminUsers error:", error);
      });

      //console.log("Calling getDropdownOptions");
      getDropDownOptions(tokenResponse.accessToken).then((result: IRegHDropdownOption[]) => {
        //console.log("getDropDownOptions response: ", result);
        //Sort Dropdown options by SortOrder.
        let sorted = result.sort(function(a, b){return a.SortIndex > b.SortIndex ? 1 : -1});
        setDropdownOptions(sorted)
        setDropdownOptionsLoaded(true);
      }).catch(error => {
        console.log("getDropDownOptions error:", error);
      });


      //console.log("Calling getActivites");
      getActivities(tokenResponse.accessToken).then((result: ICalenderDatabaseItem[]) => {
        //console.log("getActivites response: ", result);
        setCalendarItems(result)
        setCalendarItemsLoaded(true);
      }).catch(error => {
        console.log("getActivites error:", error);
      });

    }).catch(e => {
      instance.acquireTokenPopup(request).then((tokenResponse) => {
        GetData();
      });
    });
  }


  useEffect(() => {

    GetData();

  }, []);

  if (!isAllowed && hasLoaded) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: "70vh" }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="./logoRH.png" width="200" style={{ marginLeft: "1em" }} /><img src="./logoRS.png" width="150" style={{ marginLeft: "2em" }} />
        </div>
        <br />
        <span>Adgang nægtet</span>
      </div>
    );
  }

  return (
    <>
      {(currentUserRoleLoaded && dropdownOptionsLoaded && calendarItemsLoaded && hasLoaded) ?
        <>
          <Navbar currentUser={currentUser} currentUserRole={currentUserRole} dropdownOptions={dropdownOptions} itemAdded={onCalendarItemCreate} />
          <Mainview currentUser={currentUser} onCalendarItemDelete={onCalendarItemDelete} onCalendarItemUpdate={onCalendarItemUpdate} dropdownOptions={dropdownOptions} activities={calendarItems} currentUserRole={currentUserRole} />
          { showNotification ? <Notifications barType={MessageBarType.success} message={notificationMessage} /> : null }
        </>
        :
        <Grow in={true} timeout={1000}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: "70vh" }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="./logoRH.png" width="200" style={{ marginLeft: "1em" }} /><img src="./logoRS.png" width="150" style={{ marginLeft: "2em" }} />
            </div>
            <br />
            <PropagateLoader size={15} color='#122c4d' />
            <div style={{fontStyle:'italic', paddingTop:'25px'}}>Det kan tage op til 1 min at hente data.</div>
          </div>
        </Grow>
      }
    </>
  );
}



export default App;
